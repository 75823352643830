<template>
  <div class="c-three-dee-model">
    <div v-if="isLoading" class="c-three-dee-model__spinner">
      <i class="xp xp-spinner xp--large is-spinning"></i>
    </div>
    <model-viewer
    v-else
      class="c-three-dee-model__viewer"
      :src="modelSrc"
      camera-controls
      loading="auto"
    >
    </model-viewer>
  </div>
</template>
<script>

import debug from 'debug'
import '@google/model-viewer'
import entryMixin from '../../mixins/entry'

// const log = debug('xpedeo-core:ThreeDeeModelEntry')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  name: 'ThreeDeeEntry',
  mixins: [entryMixin],
  data () {
    return {
      isLoading: true,
      modelSrc: null
    }
  },
  created () {
    this.loadModel()
  },
  methods: {
    async loadModel () {
      try {
        this.isLoading = true
        this.modelSrc = await this.$xp.http.getFileUrl(this.$xp.settings.baseUrl + 'xpedeo/' + this.entryData.Content.Url, 'application/octet-stream')
      } catch (error) {
        console.log('error loading model', error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
